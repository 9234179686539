import * as bootstrap from 'bootstrap'
import Alert from 'bootstrap/js/dist/alert'
import { Tooltip, Offcanvas, Toast, Popover } from 'bootstrap'

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));


let lastVisitedLink = null;

window.copyContent =  async function copyContent(elementId, buttonId) {
    const myTooltipEl = document.getElementById(buttonId);
    const tooltip = Tooltip.getOrCreateInstance(myTooltipEl);

    let text = document.getElementById(elementId).innerHTML;
    
    if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(text);
    } else {
        // Use the 'out of viewport hidden text area' trick
        const textArea = document.createElement("textarea");
        textArea.value = text;
            
        // Move textarea out of the viewport so it's not visible
        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";
            
        document.body.prepend(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
        } catch (error) {
            console.error(error);
        } finally {
            textArea.remove();
        }
    }

    tooltip.setContent({ '.tooltip-inner': 'Link copied!' });
    setTimeout(()=>{tooltip.hide()},2000);

    try {
    } catch (err) {
        tooltip.setContent({ '.tooltip-inner': 'Failed copy!' });
        setTimeout(()=>{tooltip.hide()},2000);
    }
}

  window.onscroll = function() {scrollFunction()};

function scrollFunction() {
    if (document.body.scrollTop > 60 || document.documentElement.scrollTop > 60) {
        document.getElementById("mobile-navbar").classList.add("bg-dark-1");
    } else {
        document.getElementById("mobile-navbar").classList.remove("bg-dark-1");
    }
}

window.hideMobileMenu = function(_lastVisit){
    lastVisitedLink = _lastVisit;
    const bsOffcanvas = Offcanvas.getOrCreateInstance('#offcanvasMobileNavbar');
    bsOffcanvas.hide();
}
  

window.onload = function(){
    const myOffcanvas = document.getElementById('offcanvasMobileNavbar');
    myOffcanvas.addEventListener('hidden.bs.offcanvas', event => {
        if(lastVisitedLink == null)
            return;
        setTimeout(()=>{
            scrollToTargetAdjusted(lastVisitedLink)
            lastVisitedLink = null;
        }, 20);
    });
}

function scrollToTargetAdjusted(elementId){
    var element = document.getElementById(elementId);
    var headerOffset = 80;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  
    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });
}